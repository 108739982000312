
import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Form  } from "react-bootstrap";
import Map from '../../google-map/map';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import BtnLoader from '../../loader/btnLoader';
import Loader from '../../loader/loader';
import Checkbox from '@mui/material/Checkbox';




function WizardFormSecondPage(props) {
    const [value, setvalue] = React.useState('');

    
console.log("tablerow batch", props.tableRow)
  return (
        <div>
            <Row className="productbatchborder">
                <Col xs={12} >
                <h4>Batch List</h4>
                <div>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    MTN
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    MTSN
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Base Qty
                                </TableCell>
                                
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Color
                                </TableCell>
                                {/* <TableCell style={{fontWeight: 'bold'}}>
                                    Color Code
                                </TableCell> */}
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Size
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Image
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Check
                                </TableCell>
                                
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Purchase price
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Sales rate
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    Stock
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}} colSpan={2}>
                                    Actions
                                </TableCell>
                            </TableRow>
                            </TableHead>
                            
                            <TableBody>
                            {props.batchlistloader ? 
                            <Row>
                                <Col xs={12}>

                            <Loader />
                            </Col>
                            </Row> :
                            props.tableRow
                                .map((row) => {
                                return (
                                    <TableRow  tabIndex={-1} key={row.key}>
                                        <TableCell>
                                            {row.unit_name}
                                        </TableCell>
                                        <TableCell >
                                            {row.unit_name}
                                        </TableCell>
                                        <TableCell >
                                            {row.pb_baseqty}
                                        </TableCell>
                                        <TableCell >
                                            {row.color_name}
                                        </TableCell>
                                        {/* <TableCell >
                                            {row.pb_colorcode}
                                        </TableCell> */}
                                        <TableCell >
                                            {row.size_name}
                                        </TableCell>
                                        <TableCell >
                                        { row.pe_details
                                              .map((item) => {
                                                return(
                                                          <img src={item.pei_image} style={{width: 60, marginLeft: 10}}/>
                                                )}
                                              )
                                          }
                                        </TableCell>
                                        <TableCell >
                                            <FormControl sx={{  width: '100%' }}>
                                                    <FormControlLabel control={
                                                    <Checkbox onChange={e => props.multipleblockChange(e, row.pb_id)} />
                                                    } label="" />
                                            </FormControl>
                                        </TableCell>
                                        <TableCell >
                                            {row.pb_purchaseprice}
                                        </TableCell>
                                        <TableCell >
                                            {row.pb_salesrate}
                                        </TableCell>
                                        <TableCell >
                                            {row.pb_stock}
                                        </TableCell>
                                        {/* <TableCell >
                                            <a href="javascript:void(0)" onClick={() => props.deleteBatch(row.pb_id)}>
                                                <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                            </a>
                                        </TableCell> */}
                                        <TableCell >
                                            <a href="javascript:void(0)" onClick={() => props.editbatch(
                                                row.pb_id, 
                                                row.pb_batch,
                                                row.pb_unit,
                                                row.pb_color_fk,
                                                row.pb_size_fk,
                                                row.pb_image,
                                                row.pb_expiry_date,
                                                row.pb_purchaseprice,
                                                row.pb_salesrate,
                                                row.pb_mrp,
                                                row.pb_stock,
                                                row.pb_stockgroup,
                                                row.pb_baseqty,
                                                row.pb_b2bprice,
                                                row.pb_onlineprice,
                                                row.pb_inter_barcode,
                                                row.pb_manual_barcode,
                                                row.pb_qrcode,
                                                row.pb_hsn,
                                                row.pb_tax,
                                                row.pb_currencyqr,
                                                row.pb_currencydollar,
                                                row.pb_purchase_landingcost,
                                                row.pb_startdate,
                                                row.pb_endate,
                                                row.pb_iseditprice,
                                                row.pb_isconvertunit,
                                                row.pb_displayconvertunit)}>
                                                <FaIcons.FaEdit  color="blue" fontSize={20}  />
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                );
                                })
                                }
                            </TableBody>
                            
                        </Table>
                    </TableContainer>
                   
                    <Row>
                        <div style={{marginTop: 10}}>
                        {props.batchid == "" ? 
                            <p style={{marginBottom: 'unset', fontWeight: 'bold'}}>Add </p>: 
                            <p style={{marginBottom: 'unset',fontWeight: 'bold'}}>You are Editing Batch: {props.batch}</p>
                        }
                        </div>
                        
                        {/* <Col xs={12} md={1} style={{marginTop: 20}}>
                            <FormControl >
                                <TextField  value={props.batch} onChange={(e) => props.setBatch(e.target.value)} id="outlined-basic" label="Batch" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col> */}
                        <Col xs={12} md={2} style={{marginTop: 20}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">MTN</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={props.unit}
                                            label="Age"
                                            onChange={props.unitChange}
                                            size="small"
                                            
                                        >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {props.unitList.map((item, index) => (
                                            <MenuItem value={item.unit_id}>{item.unit_name}</MenuItem>
                                            ))}
                                        </Select>
                                </FormControl>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 20}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">MTSN</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={props.unit}
                                            label="Age"
                                            onChange={props.unitChange}
                                            size="small"
                                            
                                        >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {props.unitList.map((item, index) => (
                                            <MenuItem value={item.unit_id}>{item.unit_code}</MenuItem>
                                            ))}
                                        </Select>
                                </FormControl>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl >
                                <TextField autoComplete="off" type="number" value={props.uom} onChange={(e) => props.setUom(e.target.value)}  id="outlined-basic" label="Base Qty" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl >
                                <TextField autoComplete="off" value={props.stockgroup} onChange={(e) => props.setStockgroup(e.target.value)}  id="outlined-basic" label="Stock Group" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl >
                                <TextField autoComplete="off" type="number"  value={props.purchaseprice} onChange={(e) => props.setPurchase(e.target.value)} id="outlined-basic" label="P.rate" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl >
                                <TextField autoComplete="off" type="number" value={props.mrp} onChange={(e) => props.setMrp(e.target.value)} id="outlined-basic" label="Landing Cost" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl >
                                <TextField autoComplete="off" type="number" value={props.currencydoller} onChange={(e) => props.setCurrenctdoller(e.target.value)} id="outlined-basic" label="$" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl >
                                <TextField autoComplete="off" type="number" value={props.plc} onChange={(e) => props.setPlc(e.target.value)} id="outlined-basic" label="P.rate + LC $" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl >
                                <TextField autoComplete="off" type="number" value={props.salesrate} onChange={(e) => props.setSales(e.target.value)} id="outlined-basic" label="S.rate" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>

                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl >
                                <TextField autoComplete="off" type="number" value={props.b2b} onChange={(e) => props.setB2b(e.target.value)} id="outlined-basic" label="B2B Price" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        

                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl >
                                <TextField autoComplete="off" type="number" value={props.onlineprice} onChange={(e) => props.setOnlineprice(e.target.value)} id="outlined-basic" label="Online Price" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>

                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl >
                                <TextField min="0" disabled autoComplete="off" type="number" value={props.stock} onChange={(e) => props.changestock(e.target.value)} id="outlined-basic" label="Stock" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>

                        <Col md={2}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                  <InputLabel id="demo-simple-select-helper-label">Color</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-helper-label"
                                      id="demo-simple-select-helper"
                                      value={props.batchcolor}
                                      label="Age"
                                      onChange={props.batchcolorChange}
                                      size="small"
                                      
                                    >
                                    <MenuItem value="">
                                      <em>None</em>
                                    </MenuItem>
                                    {props.colorlist.map((item, index) => (
                                        <MenuItem value={item.color_id}>{item.color_name}</MenuItem>
                                      ))}
                                  </Select>
                            </FormControl>
                        </Col>
                        <Col md={2}>
                            <FormControl sx={{ mt: 3, width: '100%' }}>
                                  <InputLabel id="demo-simple-select-helper-label">Size</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-helper-label"
                                      id="demo-simple-select-helper"
                                      value={props.batchsize}
                                      label="Age"
                                      onChange={props.batchsizeChange}
                                      size="small"
                                      
                                    >
                                    <MenuItem value="">
                                      <em>None</em>
                                    </MenuItem>
                                    {props.sizelist.map((item, index) => (
                                        <MenuItem value={item.size_id}>{item.size_name}</MenuItem>
                                      ))}
                                  </Select>
                          </FormControl>
                        </Col>

                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl >
                                <TextField autoComplete="off" type="number" value={props.intbarcode} onChange={(e) => props.setIntbarcode(e.target.value)} id="outlined-basic" label="Barcode" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>

                        {/* <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl >
                                <TextField autoComplete="off" type="number" value={props.manbarcode} onChange={(e) => props.setManbarcode(e.target.value)} id="outlined-basic" label="Manual Barcode" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col> */}

                        {/* <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl >
                                <TextField autoComplete="off" type="number" value={props.qrcode} onChange={(e) => props.setQrcode(e.target.value)} id="outlined-basic" label="QRCode" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col> */}
                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl >
                                <TextField autoComplete="off" type="text" value={props.hsn} onChange={(e) => props.setHsn(e.target.value)} id="outlined-basic" label="HSN" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl >
                                <TextField autoComplete="off" type="text" value={props.tax} onChange={(e) => props.setTax(e.target.value)} id="outlined-basic" label="Tax" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl >
                                <TextField autoComplete="off" type="text" value={props.currencyqr} onChange={(e) => props.setCurrenctqr(e.target.value)}  id="outlined-basic" label="Currency" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={2} style={{marginTop: 20}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Expiry Date"
                                    value={props.batchexpirydate}
                                    onChange={(newValue) => {
                                    props.setBatchexpirydate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col>
                        <Col sm={3} md={2}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                    <FormControlLabel control={
                                    <Checkbox checked={props.blocked2 == 0? false : true} onChange={e => props.checkpriceChange(e)} />
                                    } label="Price Edit" />
                                </FormControl>
                        </Col>
                        <Col sm={3} md={2}>
                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                    <FormControlLabel control={
                                    <Checkbox checked={props.blocked3 == 0 || props.blocked3 == null ? false : true} onChange={e => props.checkunitChange(e)} />
                                    } label="Unit Convert" />
                                </FormControl>
                        </Col>
                        {props.blocked3 == 1 ?
                        <Col xs={12} md={3} style={{marginTop: 20}}>
                            <FormControl >
                                <TextField autoComplete="off" type="text" value={props.convertunit} onChange={(e) => props.setConvertunit(e.target.value)} id="outlined-basic" label="Unit" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col> : null}
                        {/* <Col sm={2} style={{marginTop: 20}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="End Date"
                                    value={props.enddate}
                                    onChange={(newValue) => {
                                    props.setEnddate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col> */}
                        <Col sm={3}>
                        {
                            props.batchid == "" ? 
                            <div>
                                <label>Image</label>
                                <input type="file" multiple  onChange={(e) => props.batchfile(e)}  style={{marginTop: 10}}/>
                            </div>
                            : 
                            null
                        }
                            {/* <img style={{marginTop: 10, width: 40}} src={props.file? URL.createObjectURL(props.file) : null} /> */}
                        </Col>
                        {/* <Col md={9} xs={12} style={{marginTop: 20}}>
                            {props.batchbtnloader ? 
                                <BtnLoader  style={{float: 'right'}}/>:
                                <div style={{float: 'right'}}>
                                     
                                    {
                                        props.batchid == "" ? null : 
                                        <Button onClick={props.clearBatch} style={{marginRight: 10}}  variant="contained">Clear</Button>
                                    }
                                    <Button onClick={props.addBatch}  variant="contained">{props.batchid == "" ? "Add": "Update"}</Button>
                                   
                                </div>
                            }
                        </Col> */}
                    </Row>

                    <Row style={{marginTop: 20}}>
                          <Col sm={6} style={{display: 'inline-flex'}}>
                                { props.viewimage
                                      .map((item) => {
                                        return(
                                          <div className="cardimage" style={{position: 'relative'}}> 
                                                <img className="productImagelist" src={item.pei_image} />
                                                <Button  onClick={() =>props.productImagedelete(item.pei_id) } className="imageDeletebtn" variant="contained">Delete</Button>
                                          </div>
                                        )}
                                      )
                                  }
                          </Col>
                          <Col sm={3}>
                          {
                                props.batchid == "" ? null : 
                                <div>
                                    <label>Image</label>
                                    <input type="file" multiple  onChange={(e) => props.fileSingleselectedHandler(e)}  style={{marginTop: 10}}/>
                                    {props.loader ? <Loader />:
                                        <Button onClick={props.uploadimage} style={{marginTop: 20}}  variant="contained">Upload</Button>
                                    }
                                </div>
                            }
                        </Col>
                        <Col sm={3} >
                                <div style={{float: 'right'}}>
                                    {props.batchbtnloader ? 
                                    <BtnLoader  style={{float: 'right'}}/>:
                                        <div style={{float: 'right'}}>
                                        
                                            {
                                                props.batchid == "" ? null : 
                                                <Button onClick={() => props.clearBatch("")} style={{marginRight: 10}}  variant="contained">Clear</Button>
                                            }
                                            <Button onClick={props.addBatch}  style={{float: 'right'}}  variant="contained">{props.batchid == "" ? "Add": "Update"}</Button>
                                        </div>
                                    }
                                </div>
                        
                        </Col>
                      </Row>
                    
                </div>
                </Col>
            </Row>
        </div>
  );
}

export default WizardFormSecondPage;
